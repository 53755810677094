<template>
  <div class="section">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-cascader
          label="社区"
          v-model="tenantId"
          :options="sectionOps"
          :props="props"
        />
        <v-select
          clearable
          :options="orderStatusOps"
          v-model="searchParam.status"
          label="订单状态"
        />
        <v-input label="订单编号" clearable v-model="searchParam.orderSn" />
        <v-input label="用户名" clearable v-model="searchParam.nickName" />
        <v-input label="联系电话" clearable v-model="searchParam.mobileNum" />
        <v-date-picker
          label="下单时间"
          v-model="time"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="开始时间"
          endPlaceholder="结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看电话" type="text" @click="getDecPhone(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      title="联系电话"
      v-model="dialogFormVisible"
      width="50%"
      @confirm="doCancel"
    >
      <p>{{ tel }}</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  getOrderListForManageURL,
  quarterMallGetDecPhone,
  getQuarterShopListURL,
} from "../api.js";
import {
  // shelfStsMap,
  // setShelfStsOps,
  orderStatusMap,
  setOrderStatusOps,
} from "../map.js";
import { createImgVNode } from "@/utils/utils.js";

export default {
  data() {
    return {
      // shelfStsOps: setShelfStsOps(),
      orderStatusOps: setOrderStatusOps(),
      dialogFormVisible: false,
      tel: "",
      priceOps: [
        {
          value: 0,
          label: "",
        },
        {
          value: 1,
          label: "从高到低",
        },
      ],
      searchParam: {
        tenantId: "",
        status: "",
        orderSn: "",
        nickName: "",
        mobileNum: "",
        startTime: "",
        endTime: "",
      },
      tableUrl: getOrderListForManageURL,
      headers: [
        {
          prop: "orderSn",
          label: "订单编号",
        },
        {
          prop: "tenantName",
          label: "社区",
        },
        {
          prop: "goodsName",
          label: "商品名称",
        },
        {
          prop: "picture",
          label: "商品图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "receiverName",
          label: "兑换用户",
        },
        {
          prop: "receiverPhone",
          label: "联系电话",
        },
        {
          prop: "orderGoodsNum",
          label: "下单数量",
        },
        {
          prop: "totalFee",
          label: "支付金额",
        },
        {
          prop: "status",
          label: "状态",
          formatter(row, prop) {
            return orderStatusMap[row[prop]];
          },
        },
        {
          prop: "insertTime",
          label: "下单时间",
        },
        {
          prop: "completedTime",
          label: "完成时间",
        },
      ],
      /** v-case */
      sectionOps: [],
      tenantId: [],
      props: {
        multiple: false,
        label: "name",
        value: "id",
        children: "children",
      },
    };
  },
  computed: {
    time: {
      get() {
        return !this.searchParam.beginTime && !this.searchParam.endTime
          ? []
          : [this.searchParam.beginTime, this.searchParam.endTime];
      },
      set(val) {
        [this.searchParam.beginTime, this.searchParam.endTime] = val || [
          "",
          "",
        ];
      },
    },
  },
  watch: {
    tenantId: {
      handler(val) {
        this.searchParam.tenantId = val[val.length - 1];
      },
      deep: true,
    },
  },
  methods: {
    doCancel() {
      this.dialogFormVisible = false;
    },
    // 获取商品icon图
    async getDecPhone(row) {
      let params = {
        id: row.id,
      };
      let res = await this.$axios.get(quarterMallGetDecPhone, { params });
      if (res.code == 200) {
        this.tel = res.data;
        this.dialogFormVisible = true;
      }
    },
    getQuarterShopList() {
      this.sectionOps = [];
      this.$axios.get(`${getQuarterShopListURL}`).then((res) => {
        if (res.code === 200) {
          const ops = res.data.map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
          }));
          this.sectionOps = ops;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.section {
  box-sizing: border-box;
  height: 100%;
}
</style>
